import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ContentLayout from "Components/ContentLayout";
import SubNavBar from "Components/SubNavBar";

const EnvironmentSettingsLayout = styled.div`
  width: 100%;
  background: transparent;
  > div {
    background: transparent;
  }
`;

const EnvironmentSettingsPage = styled.div`
  .settings-content {
    width: 100%;
    box-sizing: border-box;
  }
  @media (min-width: 600px) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .sidebar {
      width: 200px;
    }
    .settings-content {
      width: calc(100% - 200px);
      padding-left: 32px;
      padding-top: 12px;
    }
  }
`;

const getEnvironmentSettingTabs = (
  organizationId,
  projectId,
  environmentId
) => {
  const envId = encodeURIComponent(environmentId);
  const basePath = `/${organizationId}/${projectId}/${envId}`;

  return [
    {
      name: "general",
      route: `${basePath}/settings`,
      permission: "#edit"
    },
    {
      name: "access",
      route: `${basePath}/settings/access`,
      permission: "#manage-access"
    },
    {
      name: "variables",
      route: `${basePath}/settings/variables`,
      permission: "#manage-variables"
    },
    {
      name: "routes",
      route: `${basePath}/settings/routes`,
      permission: "#manage-routes"
    }
  ];
};

class EnvironmentSettings extends React.Component {
  render() {
    const { children, params, router, location } = this.props;

    return (
      <ContentLayout className="settings-wrapper">
        <EnvironmentSettingsPage>
          <SubNavBar
            tabs={getEnvironmentSettingTabs(
              params.organizationId,
              params.projectId,
              params.environmentId
            )}
            push={router.push}
            currentPathName={location.pathname}
            layout="vertical"
          />
          <EnvironmentSettingsLayout className="settings-content">
            {children}
          </EnvironmentSettingsLayout>
        </EnvironmentSettingsPage>
      </ContentLayout>
    );
  }
}

EnvironmentSettings.propTypes = {
  params: PropTypes.shape({
    projectId: PropTypes.string,
    environmentId: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  children: PropTypes.node
};

export default EnvironmentSettings;
